import React, { useEffect, useState } from "react";
import { axiosInstance, formatDate } from "../utills";
import { useNavigate } from "react-router-dom";
import UploadData from "./user-components/UploadData";
import DeteletData from "./user-components/DeteletData";
import Next from "../assets/next.svg";
import Pre from "../assets/previous.svg";
import Loader from "../assets/loader.gif";

function User() {
  let navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [userData, userDataUpdate] = useState(true);
  const [tempPage, updateTempPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [minValue, setMinValue] = useState(1);
  const [maxValue, setMaxValue] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [opt, updateOpt] = useState("Opt-In");
  const [load, updateLoad] = useState(false);

  // State for modal visibility and broadcast name
  const [showModal, setShowModal] = useState(false);
  const [broadcastName, setBroadcastName] = useState("");

  function handleFind() {
    console.log("handle find.");
    navigate("../inbox");
  }

  const tableColumns = [
    "Select",
    "ID",
    "Contact Id",
    "Name",
    "Phone",
    "Status",
    "Broadcast Date",
    "Last Activity Date",
    "Created At",
  ];

  // Function to handle next page
  const nextPage = () => {
    if (currentPage < totalPages) {
      updateTempPage(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to handle previous page
  const prevPage = () => {
    if (currentPage > 1) {
      updateTempPage(currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };

  const fetchUser = () => {
    if (userData === true) {
      const config = {
        method: "get",
        url: "/get_users",
      };
      updateLoad(true);
      // API call
      axiosInstance
        .request(config)
        .then((response) => {
          const {
            data: { data },
          } = response;

          console.log(response.data);
          const extractedData = response.data.map((item, index) => ({
            Select: item?.id,
            ID: index + 1,
            "Contact Id": item?.contact_id,
            Name: item?.name,
            Phone: item?.phone_number,
            Status: item?.status,
            "Broadcast Date":
              item?.last_message_date === null
                ? "-"
                : new Date(item?.last_message_date)
                    ?.toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })
                    ?.toUpperCase() || "-",
            "Last Activity Date":
              item?.last_activity_date === null
                ? "-"
                : new Date(item?.last_activity_date)
                    ?.toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })
                    ?.toUpperCase() || "-",
            "Created At":
              item?.created_at === null
                ? "-"
                : new Date(item?.created_at)
                    ?.toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })
                    ?.toUpperCase() || "-",
          }));
          setTableData(extractedData);
          userDataUpdate(false);
          updateLoad(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    fetchUser();
  }, [userData]);

  useEffect(() => {
    if (tableData) {
      setMaxValue(tableData.length);
    }
  }, [tableData]);

  const columns = tableColumns.length > 0 ? tableColumns : [];

  const getUsers = (broadcastName) => {
    const config = {
      method: "post",
      url: `/select-broadcast`,
      data: {
        selectedUsers: selectedUsers,
        broadcastName: broadcastName,
      },
    };

    // API call
    axiosInstance
      .request(config)
      .then((response) => {
        console.log("check");
        console.log(response.data);
        if (response.status === 200) {
          alert(response.data.success);
          handleFind();
          setBroadcastName("");
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.error);
      });
  };

  const paginatedFilteredData = filteredTableData.slice(startIndex, endIndex);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = filteredTableData.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredTableData.length / itemsPerPage);
    setPaginatedData(paginatedData);
    setTotalPages(totalPages);
    setStartIndex(startIndex);
    setEndIndex(endIndex);
  }, [
    currentPage,
    itemsPerPage,
    filteredTableData,
    searchQuery,
    minValue,
    maxValue,
  ]);

  useEffect(() => {
    console.log("Min Max Value is: ", minValue, " & ", maxValue);
    if (minValue === "" || maxValue === "") {
      console.log("No Update");
    } else {
      const filteredData = tableData.filter((item) => {
        const id = item.ID;
        const min = parseInt(minValue, 10);
        const max = parseInt(maxValue, 10);
        return (
          ((item["Contact Id"] || "").toString().includes(searchQuery) ||
            (item.Name || "")
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            (item.Phone || "").toString().includes(searchQuery)) &&
          id >= min &&
          id <= max &&
          (opt === "All" || item.Status === opt)
        );
      });
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const paginatedData = filteredData.slice(startIndex, endIndex);
      const totalPages = Math.ceil(filteredData.length / itemsPerPage);
      setFilteredTableData(filteredData);
      setPaginatedData(paginatedData);
      setTotalPages(totalPages);
      setStartIndex(startIndex);
      setEndIndex(endIndex);
    }
  }, [
    currentPage,
    itemsPerPage,
    tableData,
    searchQuery,
    minValue,
    maxValue,
    opt,
  ]);

  return (
    <>
      <div className="main" style={{ paddingTop: 10 }}>
        <div className="row">
          <div className="col-md-3 filter-user-container">
            <p className="filter-string">Search By:</p>
            &nbsp;&nbsp;
            <input
              type="text"
              className="form-control"
              placeholder="Contact Id, Phone Number, Name..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ width: "calc(100% - 100px)", fontSize: 12 }}
            />
          </div>
          <div className="col-md-7 id-filter-by-range">
            <p className="filter-string">ID:</p>
            &nbsp;&nbsp;
            <input
              type="number"
              className="form-control min-input"
              placeholder="Min"
              value={minValue}
              onChange={(e) => {
                setMinValue(e.target.value);
              }}
            />
            <input
              type="number"
              className="form-control max-input"
              placeholder="Max"
              value={maxValue}
              onChange={(e) => {
                setMaxValue(e.target.value);
              }}
            />
            &nbsp;
            <button
              style={{ backgroundColor: "#9ECFE8", fontSize: 12 }}
              className="btn"
              onClick={(e) => {
                setMinValue(1);
                setMaxValue(tableData.length);
              }}
            >
              Clear
            </button>
            &nbsp; &nbsp; &nbsp;
            <p className="filter-string">Status:&nbsp;</p>
            <select
              value={opt}
              onChange={(e) => updateOpt(e.target.value)}
              className="form-control"
              style={{ width: 90, fontSize: 12 }}
            >
              <option value="All">All</option>
              <option value="Opt-In">Opt-In</option>
              <option value="Opt-Out">Opt-Out</option>
            </select>
          </div>

          <div className="col-md-2 delete-div">
            <DeteletData
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              userDataUpdate={userDataUpdate}
              setCurrentPage={setCurrentPage}
              updateTempPage={updateTempPage}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 action-div">
            <button
              className="btn send-message-button"
              // onClick={() => getUsers()}
              onClick={() => setShowModal(true)}
              disabled={
                selectedUsers.length === 0 ||
                selectedUsers.some(
                  (id) =>
                    tableData.find((user) => user.Select === id).Status ===
                    "Opt-Out"
                )
              }
            >
              Send Message
            </button>
            <UploadData userDataUpdate={userDataUpdate} />
          </div>
          <div className="col-md-6 selected-users-div">
            <p className="filter-string">
              <b>Selected:</b> {selectedUsers?.length} of {tableData?.length}
            </p>
          </div>
        </div>
        {load && (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={Loader}
              alt="Loading..."
              style={{ width: 30, height: 30 }}
            />
          </div>
        )}
        {!load && (
          <div
            style={{
              padding: 12,
              borderRadius: 5,
              backgroundColor: "white",
              border: "1px solid rgba(0,0,0,0.2)",
              marginTop: 12,
            }}
          >
            <table className="table" style={{ backgroundColor: "white" }}>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th style={{ fontSize: 12 }} key={index}>
                      {index === 0 ? (
                        <input
                          type="checkbox"
                          checked={isCheckedAll}
                          onChange={(e) => {
                            if (e.target.checked) {
                              const dataToSelect =
                                filteredTableData.length > 0
                                  ? filteredTableData
                                  : tableData;
                              setSelectedUsers(
                                dataToSelect.map((item) => item.Select)
                              );
                            } else {
                              setSelectedUsers([]);
                            }
                            setIsCheckedAll(e.target.checked);
                          }}
                        />
                      ) : (
                        column
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedFilteredData.length > 0
                  ? paginatedFilteredData.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {columns.map((column, colIndex) => {
                          return (
                            <td key={colIndex} style={{ fontFamily: "arial" }}>
                              {colIndex === 0 ? (
                                <input
                                  type="checkbox"
                                  checked={selectedUsers.includes(row.Select)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedUsers([
                                        ...selectedUsers,
                                        row.Select,
                                      ]);
                                    } else {
                                      setSelectedUsers(
                                        selectedUsers.filter(
                                          (id) => id !== row.Select
                                        )
                                      );
                                    }
                                  }}
                                />
                              ) : (
                                row[column]
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    ))
                  : paginatedData.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {columns.map((column, colIndex) => {
                          return (
                            <td key={colIndex} style={{ fontFamily: "arial" }}>
                              {colIndex === 0 ? (
                                <input
                                  type="checkbox"
                                  checked={selectedUsers.includes(row.Select)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedUsers([
                                        ...selectedUsers,
                                        row.Select,
                                      ]);
                                    } else {
                                      setSelectedUsers(
                                        selectedUsers.filter(
                                          (id) => id !== row.Select
                                        )
                                      );
                                    }
                                  }}
                                />
                              ) : (
                                row[column]
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="row" style={{ marginTop: 12 }}>
          <div className="col-md-6"></div>
          <div className="col-md-6 bottom-filter-div">
            <p style={{ color: "black", fontSize: 12, marginBottom: 0 }}>
              Rows per page:
            </p>
            &nbsp;
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              className="form-control"
              style={{ width: 70, fontSize: 12 }}
            >
              <option value="10">10</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            &nbsp;&nbsp;
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className="btn btn-sm"
              style={{
                backgroundColor: currentPage === 1 ? "#9ECFE8" : "#1F9AD6",
                border: 0,
              }}
            >
              <img src={Pre} alt="Previous" />
            </button>
            <div
              style={{
                width: 80,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ fontSize: 12, marginBottom: 0, color: "black" }}>
                {tempPage} of {totalPages}
              </p>
            </div>
            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className="btn btn-sm"
              style={{
                backgroundColor:
                  currentPage === totalPages ? "#9ECFE8" : "#1F9AD6",
                border: 0,
              }}
            >
              <img src={Next} alt="Next" />
            </button>
            &nbsp;&nbsp;
            <div className="btn btn-sm specific-page-div">
              <p className="bottom-filter-string">Page</p>
              <input
                type="text"
                className="form-control"
                placeholder="1"
                value={tempPage}
                onChange={(e) => {
                  const numValue = parseInt(e.target.value, 10);
                  if (!isNaN(numValue)) {
                    if (numValue > totalPages) {
                      updateTempPage(totalPages);
                    } else {
                      updateTempPage(numValue);
                    }
                  } else {
                    updateTempPage("");
                  }
                }}
                style={{ width: 62, fontSize: 12, borderRadius: 0, border: 0 }}
              />
            </div>
            <button
              onClick={() => setCurrentPage(tempPage)}
              className="btn btn-sm go"
            >
              Go
            </button>
          </div>
        </div>
        {/* <p style={{ color: "black" }}>Selected IDs: {selectedUsers.join(", ")}</p> */}
      </div>
      {showModal && (
        <div
          style={{
            position: "absolute",
            background: "rgba(0,0,0,0.2)",
            width: "100%",
            height: "100%",
            zIndex: 10,
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            setShowModal(false);
            setBroadcastName("");
          }}
        >
          <div
            style={{
              width: "600px",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h2 style={{ marginBottom: "10px" }}>Broadcast Settings</h2>
            <p
              style={{ marginBottom: "20px", color: "black", fontWeight: 300 }}
            >
              You are about to create a broadcast channel with the selected
              users. Please provide a name for the channel before proceeding.
            </p>
            <input
              type="text"
              placeholder="Broadcast channel name"
              value={broadcastName}
              onChange={(e) => setBroadcastName(e.target.value)} // Update broadcast name
              maxLength={25}
              style={{
                width: "100%",
                padding: "10px",
                marginBottom: "20px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                style={{
                  padding: "10px 20px",
                  marginRight: "10px",
                  backgroundColor: "#f0f0f0",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowModal(false);
                  setBroadcastName("");
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#007BFF",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (broadcastName.trim() !== "") {
                    getUsers(broadcastName);
                    setShowModal(false);
                  }
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default User;
