import React, { useState, useEffect, useRef } from "react";
import { axiosInstance } from "../utills";
import Next from "../assets/next.svg";
import Pre from "../assets/previous.svg";

function Leads() {
  const [leadsData, setLeadsData] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [filterOption, setFilterOption] = useState("All");
  const [notes, setNotes] = useState({});
  const [isUpdating, setIsUpdating] = useState(null);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [tempPage, updateTempPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [paginatedData, setPaginatedData] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);

  // New state for selected leads
  const [selectedLeads, setSelectedLeads] = useState([]);
  const headerCheckboxRef = useRef(null);

  // New states for Modal
  const [showModal, setShowModal] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // New state for saving notes
  const [isSaving, setIsSaving] = useState({});

  // Fetch leads data from the API
  const fetchLeads = async () => {
    try {
      const config = {
        method: "get",
        url: "/get_leads",
      };

      const response = await axiosInstance.request(config);
      console.log("Leads Data is:", response.data);
      setLeadsData(response.data);

      // Initialize notes state with existing notes from leadsData
      const initialNotes = response.data.reduce((acc, lead) => {
        acc[lead.id] = lead.notes || "";
        return acc;
      }, {});
      setNotes(initialNotes);
    } catch (error) {
      console.error("Error fetching leads data:", error);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, []);

  useEffect(() => {
    filterLeads();
  }, [leadsData, filterOption]);

  const filterLeads = () => {
    let filtered = [];

    const currentDate = new Date();

    if (filterOption === "All") {
      filtered = leadsData;
    } else if (filterOption === "Upcoming") {
      filtered = leadsData.filter((lead) => {
        return (
          lead.appointment_date && new Date(lead.appointment_date) > currentDate
        );
      });
    } else if (filterOption === "Past Appointments") {
      filtered = leadsData.filter((lead) => {
        return (
          lead.appointment_date && new Date(lead.appointment_date) < currentDate
        );
      });
    }

    setFilteredLeads(filtered);
    setCurrentPage(1); // Reset to first page when filter changes
    setSelectedLeads([]);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";

    const date = new Date(dateString);

    // Arrays for day and month names
    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract UTC components to avoid timezone conversion
    const dayOfWeek = dayNames[date.getUTCDay()];
    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    // Get UTC hours and minutes
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    // Convert to 12-hour format
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'

    // Add leading zeros to hours and minutes
    const hoursStr = hours.toString().padStart(2, "0");
    const minutesStr = minutes.toString().padStart(2, "0");

    // Construct the time string
    const timeStr = `${hoursStr}:${minutesStr} ${ampm}`;

    // Construct the final formatted date string
    return `${dayOfWeek}, ${day} ${month} ${year}, ${timeStr}`;
  };

  const handleNoteChange = (e, leadId) => {
    setNotes({
      ...notes,
      [leadId]: e.target.value,
    });
  };

  const handleSaveNote = async (leadId) => {
    const note = notes[leadId] || "";
    const lead = leadsData.find((lead) => lead.id === leadId);
    if (!lead) {
      alert("Lead not found.");
      return;
    }

    // Set saving state for this lead
    setIsSaving((prev) => ({ ...prev, [leadId]: true }));

    try {
      const config = {
        method: "post",
        url: "/create-note",
        data: {
          phone_number: lead.phone_number,
          note: note,
        },
      };

      const response = await axiosInstance.request(config);
      console.log(`Note saved for lead ${leadId}:`, response.data.message);

      // Optionally, provide user feedback
      alert("Note saved successfully.");

      // Optionally, update leadsData with the new note
      const updatedLeads = leadsData.map((l) =>
        l.id === leadId ? { ...l, notes: note } : l
      );
      setLeadsData(updatedLeads);
    } catch (error) {
      console.error("Error saving note:", error);
      if (error.response && error.response.data && error.response.data.error) {
        alert(`Error: ${error.response.data.error}`);
      } else {
        alert("Failed to save note. Please try again.");
      }
    } finally {
      // Reset saving state for this lead
      setIsSaving((prev) => ({ ...prev, [leadId]: false }));
    }
  };

  // Function to handle next page
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to handle previous page
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Update tempPage when currentPage changes
  useEffect(() => {
    updateTempPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const totalPagesCalculated = Math.ceil(filteredLeads.length / itemsPerPage);
    setTotalPages(totalPagesCalculated);

    const startIndexCalc = (currentPage - 1) * itemsPerPage;
    const endIndexCalc = startIndexCalc + itemsPerPage;
    setStartIndex(startIndexCalc);
    setEndIndex(endIndexCalc);

    const paginatedDataCalc = filteredLeads.slice(startIndexCalc, endIndexCalc);
    setPaginatedData(paginatedDataCalc);
  }, [currentPage, itemsPerPage, filteredLeads]);

  // Handle lead status change and call API with loading and error handling
  const handleLeadStatusChange = async (leadId, newStatus) => {
    setIsUpdating(leadId); // Set the lead as updating to show a spinner

    const lead = leadsData.find((lead) => lead.id === leadId);
    if (!lead) {
      alert("Lead not found.");
      setIsUpdating(null);
      return;
    }

    const config = {
      method: "post",
      url: "/update_lead_status", // Your API endpoint for updating lead status
      data: {
        lead_id: leadId,
        lead_status: newStatus,
      },
    };

    try {
      const response = await axiosInstance.request(config);
      console.log("Lead status updated:", response.data);

      // Update the lead status locally in the state
      const updatedLeads = leadsData.map((lead) =>
        lead.id === leadId ? { ...lead, lead_status: newStatus } : lead
      );
      setLeadsData(updatedLeads);
    } catch (error) {
      console.error("Error updating lead status:", error);
      alert("Failed to update lead status. Please try again."); // Display an error message
    } finally {
      setIsUpdating(null); // Reset the updating state
    }
  };

  // Handle individual checkbox change
  const handleCheckboxChange = (leadId) => {
    setSelectedLeads((prevSelectedLeads) => {
      if (prevSelectedLeads.includes(leadId)) {
        // Remove the leadId from selectedLeads
        return prevSelectedLeads.filter((id) => id !== leadId);
      } else {
        // Add the leadId to selectedLeads
        return [...prevSelectedLeads, leadId];
      }
    });
  };

  // Handle select all checkbox
  const handleSelectAll = () => {
    if (selectedLeads.length === filteredLeads.length) {
      // All are selected, so unselect all
      setSelectedLeads([]);
    } else {
      // Select all lead IDs
      setSelectedLeads(filteredLeads.map((lead) => lead.id));
    }
  };

  // Update indeterminate state of header checkbox
  useEffect(() => {
    if (headerCheckboxRef.current) {
      headerCheckboxRef.current.indeterminate =
        selectedLeads.length > 0 && selectedLeads.length < filteredLeads.length;
    }
  }, [selectedLeads]);

  // Handle delete selected leads
  const handleDeleteSelectedLeads = async () => {
    if (selectedLeads.length === 0) return;

    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${selectedLeads.length} leads?`
    );
    if (!confirmDelete) return;

    try {
      const config = {
        method: "post",
        url: "/delete_leads", // Update to your actual endpoint
        data: {
          lead_ids: selectedLeads,
        },
      };
      const response = await axiosInstance.request(config);
      console.log("Delete response:", response.data);

      // Fetch leads data again
      await fetchLeads();

      // Clear selectedLeads
      setSelectedLeads([]);
    } catch (error) {
      console.error("Error deleting leads:", error);
      alert("Failed to delete leads. Please try again.");
    }
  };

  // Function to open the modal and fetch summary
  const handleViewSummary = async (lead) => {
    setShowModal(true);
    setSelectedLead(lead);
    setSummary("");
    setError("");
    setLoading(true);

    try {
      const config = {
        method: "post",
        url: "/get_summary",
        data: {
          phone_number: lead.phone_number,
        },
      };

      const response = await axiosInstance.request(config);
      console.log("Summary Response:", response.data);

      if (response.data.summary) {
        setSummary(response.data.summary);
      } else if (response.data.Message) {
        setSummary(response.data.Message);
      } else {
        setError("No summary available.");
      }
    } catch (error) {
      console.error("Error fetching summary:", error);
      setError("Failed to fetch summary. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
    setSelectedLead(null);
    setSummary("");
    setError("");
    setLoading(false);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "#FFA500"; // Orange for Pending
      case "Rescheduled":
        return "#FF6347"; // Tomato for Rescheduled
      case "Completed":
        return "#04AA6D"; // Green for Completed
      default:
        return "white"; // Default white color
    }
  };

  return (
    <>
      {/* Modal */}
      {showModal && (
        <div
          style={{
            position: "fixed",
            background: "rgba(0,0,0,0.2)",
            width: "100%",
            height: "100%",
            zIndex: 1000,
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={closeModal}
        >
          <div
            style={{
              maxWidth: "1000px",
              maxHeight: "80vh",
              overflowY: "auto",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h2 style={{ marginBottom: "10px" }}>AI Chat Summary</h2>
            {loading && <p style={{ color: "black" }}>Loading...</p>}
            {error && <p style={{ color: "red" }}>{error}</p>}
            {!loading && !error && summary && (
              <p
                style={{
                  color: "black",
                  whiteSpace: "pre-line",
                  fontWeight: 300,
                  lineHeight: 1.5,
                }}
              >
                {summary}
              </p>
            )}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                style={{
                  padding: "10px 20px",
                  marginTop: "20px",
                  backgroundColor: "#f0f0f0",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingTop: 10,
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 10,
          }}
        >
          <p className="filter-string">Filter Type:&nbsp;</p>
          <select
            value={filterOption}
            onChange={(e) => setFilterOption(e.target.value)}
            className="form-control"
            style={{ width: 180, fontSize: 12 }}
          >
            <option value="All">All Appointments</option>
            <option value="Upcoming">Upcoming Appointments</option>
            <option value="Past Appointments">Past Appointments</option>
          </select>
        </div>
        &nbsp;&nbsp;
        {selectedLeads.length > 0 && (
          <button
            onClick={handleDeleteSelectedLeads}
            className="btn btn-danger btn-sm"
          >
            Delete Appointment
          </button>
        )}
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                ref={headerCheckboxRef}
                checked={
                  selectedLeads.length > 0 &&
                  selectedLeads.length === filteredLeads.length
                }
                onChange={handleSelectAll}
              />
            </th>
            <th>SNo</th>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Appointment Date</th>
            <th>Lead Status</th>
            <th>AI Chat Summary</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((lead, index) => (
            <tr key={lead.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedLeads.includes(lead.id)}
                  onChange={() => handleCheckboxChange(lead.id)}
                />
              </td>
              <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
              <td>{lead.name}</td>
              <td>{lead.phone_number}</td>
              <td>{formatDate(lead.appointment_date)}</td>
              <td>
                <select
                  value={lead.lead_status || "Pending"} // Default to "Pending" if lead_status is not set
                  onChange={(e) =>
                    handleLeadStatusChange(lead.id, e.target.value)
                  }
                  style={{
                    backgroundColor: getStatusColor(lead.lead_status),
                    borderColor: getStatusColor(lead.lead_status),
                    color: "white",
                  }}
                  className="form-control form-control-sm"
                >
                  <option
                    value="Pending"
                    style={{ backgroundColor: "#FFA500", color: "white" }}
                  >
                    Pending
                  </option>
                  <option
                    value="Rescheduled"
                    style={{ backgroundColor: "#FF6347", color: "white" }}
                  >
                    Rescheduled
                  </option>
                  <option
                    value="Completed"
                    style={{ backgroundColor: "#04AA6D", color: "white" }}
                  >
                    Completed
                  </option>
                </select>
                {isUpdating === lead.id && (
                  <span className="spinner-border spinner-border-sm ml-2"></span>
                )}
              </td>
              <td>
                <button
                  onClick={() => handleViewSummary(lead)}
                  className="btn btn-sm"
                  style={{ backgroundColor: "rgb(31, 154, 214)", color: "white" }}
                >
                  View Summary
                </button>
              </td>
              <td style={{ display: "flex", flexDirection: "row" }}>
                <input
                  type="text"
                  name={`note_${lead.id}`}
                  value={notes[lead.id] || ""}
                  onChange={(e) => handleNoteChange(e, lead.id)}
                  className="form-control form-control-sm"
                  style={{ flex: 1 }}
                />
                <div style={{ width: 15 }}></div>
                <button
                  onClick={() => handleSaveNote(lead.id)}
                  className="btn btn-success btn-sm"
                  disabled={isSaving[lead.id] || false}
                >
                  {isSaving[lead.id] ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Save"
                  )}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Pagination Controls */}
      <div className="row">
        <div className="col-md-6"></div>
        <div className="col-md-6 bottom-filter-div">
          <p style={{ color: "black", fontSize: 12, marginBottom: 0 }}>
            Rows per page:
          </p>
          &nbsp;
          <select
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
            className="form-control"
            style={{ width: 70, fontSize: 12 }}
          >
            <option value="10">10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          &nbsp;&nbsp;
          <button
            onClick={prevPage}
            disabled={currentPage === 1}
            className="btn btn-sm"
            style={{
              backgroundColor: currentPage === 1 ? "#9ECFE8" : "#1F9AD6",
              border: 0,
            }}
          >
            <img src={Pre} alt="Previous" />
          </button>
          <div
            style={{
              width: 80,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ fontSize: 12, marginBottom: 0, color: "black" }}>
              {tempPage} of {totalPages}
            </p>
          </div>
          <button
            onClick={nextPage}
            disabled={currentPage === totalPages || totalPages === 0}
            className="btn btn-sm"
            style={{
              backgroundColor:
                currentPage === totalPages || totalPages === 0
                  ? "#9ECFE8"
                  : "#1F9AD6",
              border: 0,
            }}
          >
            <img src={Next} alt="Next" />
          </button>
          &nbsp;&nbsp;
          <div className="btn btn-sm specific-page-div">
            <p className="bottom-filter-string">Page</p>
            <input
              type="text"
              className="form-control"
              placeholder="1"
              value={tempPage}
              onChange={(e) => {
                const numValue = parseInt(e.target.value, 10);
                if (!isNaN(numValue)) {
                  if (numValue > totalPages) {
                    updateTempPage(totalPages);
                  } else if (numValue < 1) {
                    updateTempPage(1);
                  } else {
                    updateTempPage(numValue);
                  }
                } else {
                  updateTempPage("");
                }
              }}
              style={{ width: 62, fontSize: 12, borderRadius: 0, border: 0 }}
            />
          </div>
          <button
            onClick={() => {
              const pageNum = parseInt(tempPage, 10);
              if (!isNaN(pageNum) && pageNum >= 1 && pageNum <= totalPages) {
                setCurrentPage(pageNum);
              }
            }}
            className="btn btn-sm"
            style={{backgroundColor:"#1f9ad6", color:"white"}}
          >
            Go
          </button>
        </div>
      </div>
    </>
  );
}

export default Leads;
