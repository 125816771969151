import React from "react";
import Facebook from "../assets/facebook.png";
import Google from "../assets/google.png";
import Instagram from "../assets/instagram.png";
import Whatsapp from "../assets/whatsapp.png";
import Sms from "../assets/sms.png";

const icons = [
  { name: "Facebook", icon: Facebook },
  { name: "Google", icon: Google },
  { name: "Instagram", icon: Instagram },
  { name: "Whatsapp", icon: Whatsapp },
  { name: "Sms", icon: Sms },
];

function Settings() {
  return (
    <>
      {icons.map((icon, index) => (
        <div className="row" style={{display:"flex", flexDirection:"row", justifyContent:"center", marginTop:10}}>
          <div
            key={index}
            className="col-md-5"
            style={{
              height: 70,
              backgroundColor: "#ededed",
              padding: 10,
              display: "flex",
              flexDirection: "row",
              alignItems:"center", 
              borderRadius:10
            }}
          >
            <img
              src={icon.icon}
              alt={icon.name}
              style={{ width: 50, height: 50 }}
            />
            <h3 style={{paddingLeft:15, marginBottom:0, fontSize:18}}>{icon.name}</h3>
          </div>
        </div>
      ))}
    </>
  );
}

export default Settings;
