import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../utills";

function DeteletData({
  selectedUsers,
  setSelectedUsers,
  userDataUpdate,
  setCurrentPage,
  updateTempPage,
}) {
  const [deleting, updateDeleting] = useState(false);

  useEffect(() => {
    console.log("Delete Data: Selected Users->", selectedUsers.join(", "));
  }, [selectedUsers]);

  const handleDelete = async () => {
    updateDeleting(true);
    const config = {
      method: "post",
      url: "/delete-users",
      data: {
        ids: selectedUsers,
      },
    };

    axiosInstance
      .request(config)
      .then((response) => {
        console.log("Delete API");
        console.log("response.data: ", response.data);
        if (response.status === 200 || response.status === 201) {
          updateDeleting(false);
          userDataUpdate(true);
          setSelectedUsers([]);
          setCurrentPage(1);
          updateTempPage(1);
          alert("Users Deleted Successfully!");
        }
        updateDeleting(false);
        userDataUpdate(true);
        setSelectedUsers([]);
        setCurrentPage(1);
        updateTempPage(1);
      })
      .catch((error) => {
        console.log(error);
        updateDeleting(false);
        userDataUpdate(true);
        setSelectedUsers([]);
        setCurrentPage(1);
        updateTempPage(1);
        alert("Error! Try again later.");
      });
  };

  return (
    <>
      {selectedUsers.length > 0 &&
        (deleting ? (
          <button
            className="btn"
            style={{
              backgroundColor: "tomato",
              color: "white",
              marginRight: 10,
              fontSize: 12,
            }}
            disabled={true}
          >
            Deleting...
          </button>
        ) : (
          <button
            className="btn"
            style={{
              backgroundColor: "tomato",
              color: "white",
              marginRight: 10,
              fontSize: 12,
            }}
            onClick={() => handleDelete()}
          >
            Delete Users
          </button>
        ))}
    </>
  );
}

export default DeteletData;
