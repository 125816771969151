// ZoomAnalysis.jsx

import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import "./ZoomAnalysis.css"; // Import the CSS file

const ZoomAnalysis = () => {
  // State variables
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true); // Indicates if data is being loaded
  const [error, setError] = useState(null); // Stores any error messages

  // Filters state
  const [searchQuery, setSearchQuery] = useState("");
  const [repType, setRepType] = useState("All");
  const [ratingMin, setRatingMin] = useState("");
  const [ratingMax, setRatingMax] = useState("");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [tempPage, setTempPage] = useState(1); // For "Go to page" functionality

  // Define which columns require increased width
  const wideColumns = new Set([
    "Session Date",
    "Name",
    "Email",
    "Participants",
    "Session Duration",
    "Session Strengths",
    "Session Weaknesses",
    "Recommendation for Improvement",
    "Conclusion",
    "Red Flags",
    "Orange Flags",
    "Yellow Flags",
    "Green Flags",
    "Rating Justification",
    "Lead Intent Score", 
    "Lead Intent Score Justification",
    "Summary", // Added
  ]);

  // Define column widths (you can adjust the pixel values as needed)
  const columnWidths = {
    "ID": "40px",
    "Zoom Link": "100px",
    "Session Date": "100px",
    "Name": "120px",
    "Email": "120px",
    "Representative": "190px",
    "Participants": "150px",
    "Session Duration": "100px",
    "Rating": "80px",
    "Rating Justification": "200px",
    "Lead Intent Score": "120px",
    "Lead Intent Score Justification": "200px",
    "Summary": "1200px", // Adjusted
    "Session Strengths": "500px",
    "Session Weaknesses": "500px",
    "Recommendation for Improvement": "400px",
    "Conclusion": "400px",
    "Red Flags": "700px",
    "Orange Flags": "700px",
    "Yellow Flags": "1200px",
    "Green Flags": "1200px",
  };

  // Helper function to format session date
  const formatSessionDate = (dateString) => {
    if (!dateString) return "N/A";
  
    try {
      const date = new Date(dateString);
      
      // Format the date part
      const dateOptions = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        timeZone: 'UTC',
      };
      const formattedDate = new Intl.DateTimeFormat('en-GB', dateOptions).format(date);
      
      // Format the time part
      const timeOptions = {
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
        timeZone: 'UTC',
      };
      const formattedTime = new Intl.DateTimeFormat('en-GB', timeOptions).format(date);
      
      // Return as JSX elements separated by line breaks
      return (
        <>
          <div>{formattedDate}</div>
          <div>{formattedTime}</div>
        </>
      );
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Invalid Date";
    }
  };
  
  

  // Helper function to process Participants column
  const processParticipants = (row) => {
    if (!row.participants) return "N/A";

    // Assuming participants are stored as a comma-separated string
    let members = row.participants.split(',').map(name => name.trim());

    // Remove duplicate names
    members = [...new Set(members)];

    // Remove strategist's name if it exists in members
    if (row.strategist) {
      const strategistName = row.strategist.trim().toLowerCase();
      members = members.filter(member => member.toLowerCase() !== strategistName);
    }

    // If no members left after removal
    if (members.length === 0) return "N/A";

    return members.join(', ');
  };

  // Handler for updating representative_type
  const handleRepresentativeTypeChange = async (strategist_email, newType) => {
    console.log(`Updating Representative Type for email: ${strategist_email}, New Type: ${newType}`);

    // Determine the value to send to the API
    const representative_type = newType === "Null" ? null : newType;

    try {
      // Send PATCH request to update representative_type
      const response = await axios.patch("https://zoom.kuru.ai/update_representative_type", {
        strategist_email: strategist_email.trim().toLowerCase(),
        representative_type: representative_type,
      });

      if (response.status === 200) {
        console.log(`Updated rows for email: ${strategist_email}`);

        // Update the local tableData state for all matching emails (case-insensitive)
        setTableData((prevData) =>
          prevData.map((row) =>
            row.strategist_email.trim().toLowerCase() === strategist_email.trim().toLowerCase()
              ? { ...row, representative_type: newType === "Null" ? null : newType }
              : row
          )
        );
        alert("Representative Type updated successfully.");
      } else {
        // Handle unexpected responses
        alert("Failed to update Representative Type. Please try again.");
      }
    } catch (error) {
      console.error("Error updating Representative Type:", error);
      alert("An error occurred while updating Representative Type. Please try again.");
      // Optionally, you can refresh the data or revert the change
    }
  };

  // Define table columns with updated names
  const tableColumns = [
    "ID",
    "Zoom Link",
    "Session Date",
    "Name",              // Changed from "Strategist" to "Name"
    "Email",             // Changed from "Strategist Email" to "Email"
    "Representative",
    "Participants",
    "Session Duration",
    "Rating",
    "Rating Justification",
    "Lead Intent Score",
    "Lead Intent Score Justification",
    "Summary",           // Added
    "Session Strengths",
    "Session Weaknesses",
    "Recommendation for Improvement",
    "Conclusion",
    "Red Flags",
    "Orange Flags",
    "Yellow Flags",
    "Green Flags",
  ];

  // Helper function to get CSS class based on column name
  const getColumnClass = (column) => {
    switch (column) {
      case "ID":
        return "col-id";
      case "Zoom Link":
        return "col-zoom-link";
      case "Session Date":
        return "col-session-date";
      case "Name":
        return "col-name";
      case "Email":
        return "col-email";
      case "Representative":
        return "col-rep-type";
      case "Participants":
        return "col-participants";
      case "Session Duration":
        return "col-session-duration";
      case "Rating":
        return "col-rating-out-of-5";
      case "Rating Justification":
        return "col-rating-justification";
      case "Lead Intent Score":
        return "col-lead-intent-score";
      case "Lead Intent Score Justification":
        return "col-lead-intent-score-justification";
      case "Summary":
        return "col-summary"; // Added
      case "Session Strengths":
        return "col-session-strengths";
      case "Session Weaknesses":
        return "col-session-weaknesses";
      case "Recommendation for Improvement":
        return "col-recommendation-for-improvement";
      case "Conclusion":
        return "col-conclusion";
      case "Red Flags":
        return "col-red-flags";
      case "Orange Flags":
        return "col-orange-flags";
      case "Yellow Flags":
        return "col-yellow-flags";
      case "Green Flags":
        return "col-green-flags";
      default:
        return "";
    }
  };

  // Helper function to render cell data based on column name
  const renderCellData = (column, row) => {
    switch (column) {
      case "ID":
        return row.id;
      case "Zoom Link":
        return (
          <a href={row.zoom_link} target="_blank" rel="noopener noreferrer">
            Video Link
          </a>
        );
      case "Session Date":
        return formatSessionDate(row.session_date);
      case "Name": // Updated from "Strategist"
        return (
          <span className="cell-content" title={row.strategist}>
            {row.strategist}
          </span>
        );
      case "Email": // Updated from "Strategist Email"
        return (
          <span className="cell-content" title={row.strategist_email}>
            {row.strategist_email}
          </span>
        );
      case "Representative":
        return (
          <select
            value={row.representative_type || "Null"}
            onChange={(e) => handleRepresentativeTypeChange(row.strategist_email, e.target.value)}
            className="form-control"
            style={{ fontSize: "12px" }}
            aria-label={`Select Representative Type for ${row.strategist_email}`}
          >
            <option value="Null">Null</option>
            <option value="Strategist">Strategist</option>
            <option value="Specialist">Specialist</option>
            <option value="Investment Leading Manager">Investment Leading Manager</option>
          </select>
        );
      case "Session Duration":
        return row.session_duration !== null ? `${row.session_duration} mins` : "N/A";
      case "Rating":
        return row.rating_out_of_5 !== null ? `${row.rating_out_of_5 * 2} / 10` : "N/A";
      case "Rating Justification":
      case "Lead Intent Score Justification":
        return (
          <span className="multiline-content" title={row[column.toLowerCase().replace(/ /g, "_")]}>
            {row[column.toLowerCase().replace(/ /g, "_")]}
          </span>
        );
      case "Lead Intent Score":
        return row.lead_intent_score !== null && row.lead_intent_score !== undefined? `${row.lead_intent_score} / 10`
        : "N/A";
      case "Summary": // Added
        return (
          <span className="multiline-content" title={row.summary_of_session}>
            {row.summary_of_session}
          </span>
        );
      case "Session Strengths":
        return row.strengths_of_the_session;
      case "Session Weaknesses":
        return row.weaknesses_of_the_session;
      case "Recommendation for Improvement":
        return row.recommendation_for_improvement;
      case "Conclusion":
        return row.conclusion;
      case "Red Flags":
        return row.red_flags;
      case "Orange Flags":
        return row.orange_flags;
      case "Yellow Flags":
        return row.yellow_flags;
      case "Green Flags":
        return row.green_flags;
      case "Participants":
        return processParticipants(row); // Use the helper function
      default:
        // Convert column names to corresponding object keys
        return row[column.toLowerCase().replace(/ /g, "_")];
    }
  };

  // Fetch data from the API
  useEffect(() => {
    const fetchZoomData = async () => {
      try {
        const response = await axios.get("https://zoom.kuru.ai/data");
        const data = response.data;

        // Normalize `strategist_email` to lowercase and trim whitespace for consistency
        const normalizedData = data.map((row) => ({
          ...row,
          strategist_email: row.strategist_email ? row.strategist_email.trim().toLowerCase() : row.strategist_email,
        }));

        // Set the table data
        setTableData(normalizedData);

        // Calculate total pages based on data length and rows per page
        setTotalPages(Math.ceil(normalizedData.length / rowsPerPage));

        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch data. Please try again later.");
        setLoading(false);
      }
    };

    fetchZoomData();
  }, []); // Empty dependency array ensures this runs once on mount

  // Filter the data based on search and filter criteria
  const filteredData = useMemo(() => {
    return tableData.filter((row) => {
      // Search filter
      const searchLower = searchQuery.toLowerCase();
      const matchesSearch =
        (row.file_name && row.file_name.toLowerCase().includes(searchLower)) ||
        (row.conclusion && row.conclusion.toLowerCase().includes(searchLower)) ||
        (row.strategist_email && row.strategist_email.toLowerCase().includes(searchLower)) ||
        (row.strategist && row.strategist.toLowerCase().includes(searchLower));

      // Representative Type filter
      const matchesRepType =
        repType === "All" ||
        (row.representative_type && row.representative_type.toLowerCase() === repType.toLowerCase());

      // Rating filter
      const rating = row.rating_out_of_5 !== null ? row.rating_out_of_5 * 2 : null;
      const matchesRating =
        (ratingMin === "" || rating >= Number(ratingMin)) &&
        (ratingMax === "" || rating <= Number(ratingMax));

      return matchesSearch && matchesRepType && matchesRating;
    });
  }, [
    tableData,
    searchQuery,
    repType,
    ratingMin,
    ratingMax,
  ]);

  // Update pagination based on filtered data
  useEffect(() => {
    setTotalPages(Math.ceil(filteredData.length / rowsPerPage));
    setCurrentPage(1); // Reset to first page when filters change
    setTempPage(1);
  }, [filteredData, rowsPerPage]);

  // Calculate current data slice
  const currentRows = useMemo(() => {
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    return filteredData.slice(indexOfFirstRow, indexOfLastRow);
  }, [filteredData, currentPage, rowsPerPage]);

  // Handle page change
  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
    setTempPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    setTempPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handleGoToPage = (pageNumber) => {
    const page = Number(pageNumber);
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      setTempPage(page);
    }
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page
    setTempPage(1);
    setTotalPages(Math.ceil(filteredData.length / Number(e.target.value)));
  };

  return (
    <div className="main" style={{ paddingTop: 10 }}>
      {/* Filters */}
      <div className="row">
        <div className="col-md-3 filter-user-container">
          <p className="filter-string" style={{ fontWeight: 200, color: "grey" }}>Search:</p>
          &nbsp;&nbsp;
          <input
            type="text"
            className="form-control"
            placeholder="Name, Email..."
            style={{ fontSize: 12 }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="col-md-5 filter-user-container">
          <p className="filter-string" style={{ fontWeight: 200, color: "grey", fontSize: 14 }}>Representative:</p>
          &nbsp;&nbsp;
          <select
            className="form-control"
            style={{ fontSize: 12, width: 190 }}
            value={repType}
            onChange={(e) => setRepType(e.target.value)}
          >
            <option value="All">All</option>
            <option value="Specialist">Specialist</option>
            <option value="Strategist">Strategist</option>
            <option value="Investment Leading Manager">Investment Leading Manager</option>
          </select>
        </div>
      </div>
      <div className="row" style={{ marginTop: 12 }}>
        <div
          className="col-md-12"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p className="filter-string" style={{ fontWeight: 200, color: "grey", fontSize: 14 }}>Rating out of 5:</p>
          &nbsp;&nbsp;
          <input
            type="number"
            className="form-control min-input"
            placeholder="Min"
            value={ratingMin}
            onChange={(e) => setRatingMin(e.target.value)}
            min="0"
            max="10"
            style={{ fontSize: 12 }}
          />
          <input
            type="number"
            className="form-control max-input"
            placeholder="Max"
            value={ratingMax}
            onChange={(e) => setRatingMax(e.target.value)}
            min="0"
            max="10"
            style={{ fontSize: 12 }}
          />
        </div>
      </div>
      {/* Table */}
      <div
        className="table-container"
        style={{
          padding: 12,
          borderRadius: 5,
          backgroundColor: "white",
          border: "1px solid rgba(0,0,0,0.2)",
          marginTop: 12,
          overflowX: "auto", // Enable horizontal scrolling
        }}
      >
        {loading ? (
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>
        ) : error ? (
          <p style={{ color: "red" }}>{error}</p>
        ) : (
          <table className="table zoom-analysis-table">
            <colgroup>
              {tableColumns.map((column, index) => (
                <col
                  key={index}
                  style={{ width: columnWidths[column] || "auto" }}
                />
              ))}
            </colgroup>
            <thead>
              <tr>
                {tableColumns.map((column, index) => (
                  <th
                    className={`zoom-table-header ${
                      wideColumns.has(column) ? "wide-column" : ""
                    } ${getColumnClass(column)}`} // Apply column width class
                    key={index}
                  >
                    {column === "Participants" ? "Members Name" : column}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentRows.length > 0 ? (
                currentRows.map((row) => (
                  <tr key={row.id}> {/* Ensure 'id' is unique */}
                    {tableColumns.map((column) => (
                      <td
                        className={`zoom-table-cell ${
                          wideColumns.has(column) ? "wide-column" : ""
                        } ${getColumnClass(column)}`} // Apply column width class
                        key={column} // Using column name as key
                      >
                        {renderCellData(column, row)}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableColumns.length}
                    style={{ textAlign: "center" }}
                  >
                    No data available.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      {/* Pagination Component */}
      {!loading && !error && tableData.length > 0 && (
        <div className="row" style={{ marginTop: 12 }}>
          <div className="col-md-6"></div>
          <div
            className="col-md-6 bottom-filter-div"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <p style={{ color: "black", fontSize: 12, marginBottom: 0 }}>
              Rows per page:
            </p>
            &nbsp;
            <select
              className="form-control"
              style={{ width: 70, fontSize: 12 }}
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
            >
              <option value="10">10</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            &nbsp;&nbsp;
            <button
              className="btn btn-sm pagination-button"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <div className="pagination-info">
              <p style={{ fontSize: 12, marginBottom: 0, color: "black" }}>
                {currentPage} of {totalPages}
              </p>
            </div>
            <button
              className="btn btn-sm pagination-button"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
            &nbsp;&nbsp;
            <div className="pagination-go">
              <p className="bottom-filter-string" style={{ margin: 0 }}>
                Page
              </p>
              &nbsp;
              <input
                type="number"
                className="form-control go-input"
                placeholder="1"
                min="1"
                max={totalPages}
                value={tempPage}
                onChange={(e) => setTempPage(e.target.value)}
                style={{ width: "50px", fontSize: "12px" }}
              />
              <button
                className="btn btn-sm go-button"
                onClick={() => handleGoToPage(tempPage)}
              >
                Go
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ZoomAnalysis;
