import React, { useState, useEffect } from "react";
import ChatList from "./inbox-components/ChatList";
import ChatBox from "./inbox-components/ChatBox";
import socketIOClient from "socket.io-client";
import { baseURL } from "../utills";

function Inbox() {
  const [selectedChat, updateSelectedChat] = useState({ type: null, index: 0 });
  const [chatList, updateChatList] = useState([]);
  const [broadcastChatList, updateBroadcastChatList] = useState([]);
  const [trigger, updateTrigger] = useState("");
  useEffect(() => {
    const socket = socketIOClient(baseURL, {
      transports: ["websocket"], // Force WebSocket
      reconnection: true, // Enable automatic reconnection
      reconnectionAttempts: 5, // Attempt reconnection 5 times
      reconnectionDelay: 1000, // 1 second delay between reconnections
      reconnectionDelayMax: 5000, // Maximum 5 seconds delay
    });
    socket.on("message", (data) => {
      console.log("Socket Connected and Message Received");
      updateTrigger(data);
    });

    // Cleanup the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    console.log("Triggered Message Is", trigger);
  }, [trigger]);

  return (
    <div className="row">
      <div className="cont">
        <ChatList
          selectedChat={selectedChat}
          updateSelectedChat={updateSelectedChat}
          chatList={chatList}
          updateChatList={updateChatList}
          broadcastChatList={broadcastChatList}
          updateBroadcastChatList={updateBroadcastChatList}
          trigger={trigger}
        />
        <ChatBox
          selectedChat={selectedChat}
          chatList={chatList}
          broadcastChatList={broadcastChatList}
          trigger={trigger}
        />
      </div>
    </div>
  );
}

export default Inbox;
