import React, { useState } from "react";
import Logo from "../assets/logo_white_freedom.png";
import TextField from "@mui/material/TextField";

import Cookies from "js-cookie";
import { axiosInstance } from "../utills";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if either username or password is empty
    if (!username || !password) {
      alert("Please fill in both username and password fields.");
      return;
    }

    if (username === "admin" && password === "admin@123") {
      window.location.href = "/dashboard";
    } else {
      alert("Check Username and Password");
    }
  };

  return (
    <div className="container-fluid bg-blue flex-row-align-center" style={{height:"100vh"}}>
      <div className="row" style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
        <div className="col-md-4 login-card" style={{minWidth:500}}>
          <center>
            <img
              src={Logo}
              alt="Logo"
              style={{
                height: "50px",
              }}
            />
          </center>
          <p className="bm-semi xl accent" style={{ marginTop: 57 }}>
            Admin Panel
          </p>
          <p style={{ marginTop: 15 }}>
            This page is exclusively intended for authorized personnel
            associated with our organization. If you are not a member of our
            organization's staff or management, please be advised that you have
            landed on the admin page, which is reserved for internal use only.
          </p>

          <form onSubmit={handleSubmit}>
            <TextField
              id="filled-basic"
              label="Username"
              variant="filled"
              size="small"
              style={{
                marginTop: 30,
              }}
              className="login-text-field"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              id="filled-basic"
              type="password"
              label="Password"
              variant="filled"
              size="small"
              style={{
                marginTop: 15,
              }}
              className="login-text-field"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <center>
              <button type="submit" className="login-button">
                LOGIN
              </button>
            </center>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
